import store from "@/store"
import { PlanEntry } from "@/classes/PlanEntry"
import { query, where, collection, onSnapshot, getDocs } from "firebase/firestore"
import { firestore } from "@/firebase"
import { endOfDay, endOfWeek, format, startOfDay, startOfWeek } from "date-fns"

const planEntriesRef = collection(firestore, "entries")

const state = {
    entries: [],
    ownEntries: [],
    ownPools: [],
    loading: {
        ownEntries: true,
        entries: true
    },
    firebaseListeners: {
        ownPlanEntries: null,
        planEntries: null,
        lastAttachedFromDate: null,
        lastAttachedToDate: null,
        lastAttachedPool: null,
        lastAttachedDate: null
    }
}
const getters = {

}
const actions = {
    attachOwnPlanEntries({ state }, { from, to }) {
        return new Promise((resolve, reject) => {
            if (!from || !to) {
                reject("invalid_arguments")
                return
            }
            if (state.firebaseListeners.lastAttachedFromDate != from || state.firebaseListeners.lastAttachedToDate != to) {
                if (state.firebaseListeners.ownPlanEntries) {
                    state.firebaseListeners.ownPlanEntries()
                    state.firebaseListeners.ownPlanEntries = null
                }
                state.firebaseListeners.lastAttachedFromDate = null
                state.firebaseListeners.lastAttachedToDate = null
            } else if (state.firebaseListeners.ownPlanEntries) {
                state.loading.ownEntries = false
                resolve(state.ownEntries)
                return
            }
            state.firebaseListeners.lastAttachedFromDate = from
            state.firebaseListeners.lastAttachedToDate = to
            state.loading.ownEntries = true
            state.firebaseListeners.ownPlanEntries = onSnapshot(query(
                planEntriesRef,
                where("employee.personalID", "==", store.state.auth.userClaims.personalID),
                where("from", ">=", startOfDay(new Date(from))),
                where("from", "<=", endOfDay(new Date(to)))
            ), (snapshot) => {
                state.ownEntries = []
                for (const doc of snapshot.docs) {
                    state.ownEntries.push(new PlanEntry(doc))
                }
                state.loading.ownEntries = false
                resolve(state.ownEntries)
            }, (err) => {
                state.loading.ownEntries = false
                reject(err)
            })
        })
    },
    getOwnPools({ state }, { date }) {
        return new Promise(async (resolve, reject) => {
            if (!date) {
                reject("invalid_arguments")
                return
            }
            const from = startOfWeek(date, { weekStartsOn: 1 })
            const to = endOfWeek(date, { weekStartsOn: 1 })
            const formattedFrom = format(from, "yyyyMMdd")
            const cachedElement = state.ownPools.find((a) => a.week == formattedFrom)
            if (cachedElement) {
                resolve(cachedElement.pools)
                return
            }
            const resolvedDocs = await getDocs(query(
                planEntriesRef,
                where("employee.personalID", "==", store.state.auth.userClaims.personalID),
                where("from", ">=", startOfDay(from)),
                where("from", "<=", endOfDay(to))
            ))
            const pools = []
            for (const doc of resolvedDocs.docs) {
                const data = doc.data()
                for (const pool of data.pool.pools) {
                    if (!pools.includes(pool)) {
                        pools.push(pool)
                    }
                }
            }
            state.ownPools.push({
                week: formattedFrom,
                pools: pools
            })
            resolve(pools)
        })
    },
    detachPlanEntries({ state }) {
        if (state.firebaseListeners.planEntries) {
            state.firebaseListeners.planEntries()
            state.firebaseListeners.planEntries = null
        }
    },
    attachPlanEntries({ state }, { pool, date }) {
        return new Promise((resolve, reject) => {
            if (!pool || !date) {
                reject("invalid_arguments")
                return
            }
            if (state.firebaseListeners.lastAttachedPool != pool || state.firebaseListeners.lastAttachedDate != date) {
                if (state.firebaseListeners.planEntries) {
                    state.firebaseListeners.planEntries()
                    state.firebaseListeners.planEntries = null
                }
                state.firebaseListeners.lastAttachedPool = null
                state.firebaseListeners.lastAttachedDate = null
            } else if (state.firebaseListeners.planEntries) {
                state.loading.entries = false
                resolve(state.entries)
                return
            }
            state.firebaseListeners.lastAttachedPool = pool
            state.firebaseListeners.lastAttachedDate = date
            state.loading.entries = true
            state.firebaseListeners.planEntries = onSnapshot(query(
                planEntriesRef,
                where("pool.pools", "array-contains", pool),
                where("from", ">=", startOfDay(date)),
                where("from", "<=", endOfDay(date))
            ), (snapshot) => {
                state.entries = []
                for (const doc of snapshot.docs) {
                    state.entries.push(new PlanEntry(doc))
                }
                state.loading.entries = false
                resolve(state.entries)
            }, (err) => {
                state.loading.entries = false
                reject(err)
            })
        })
    }
}
export default { state, getters, actions }