import { firestore } from "@/firebase"
import { collection, doc, onSnapshot } from "firebase/firestore"

const state = {
    isNEWApp: false,
    config: {
        showNextYear: {
            day: 1,
            month: 10
        }
    },
    dialogs: {
        switchView: false
    },
    alert: {
        title: "",
        message: "",
        show: false
    }
}
const mutations = {
    showAlert(state, payload) {
        state.alert.title = payload.title
        state.alert.message = payload.message
        state.alert.show = true
    }
}
const actions = {
    attachConfig({ state }) {
        const configRef = doc(collection(firestore, "config"), "default")
        onSnapshot(configRef, (snapshot) => {
            let data = {}
            if (snapshot.exists()) {
                data  =snapshot.data()
            }
            state.config.showNextYear.day = data.showNextYear ? data.showNextYear.day : 1
            state.config.showNextYear.month = data.showNextYear ? data.showNextYear.month : 10
        })
    }
}

if (navigator.userAgent === "MAPP") {
    state.isNEWApp = true
}

loginNew.onNEWApp(() => {
    state.isNEWApp = true
})

export default { state, mutations, actions }