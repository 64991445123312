import { waitForFirebaseAuth, auth, functions } from "./firebase"
import { getIdTokenResult, signInWithCustomToken } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import store from "./store"
import router from "./router"

loginNew.init(
    process.env.VUE_APP_LOGIN_NEW_CLIENT_ID,
    location.protocol + "//" + location.host + "/login",
    [],
    "openid profile email aid signin:microsoft"
)
loginNew.onAuthStateChanged(async () => {
    store.state.auth.isAuthenticating = true
    if(loginNew.isSignedIn()) {
        await waitForFirebaseAuth()
        const profile = loginNew.getProfile()
        const fetchNewIdToken = async () => {
            const getAuthToken = httpsCallable(functions, "getAuthToken")
            const response = await getAuthToken({ token: loginNew.getIdToken() })
            if (response.data.response === "ok") {
                await signInWithCustomToken(auth, response.data.token)
                const tokenResult = await getIdTokenResult(auth.currentUser, true)
                store.commit("setUserClaims", tokenResult.claims)
                store.commit("setUserProfile", profile)
                store.state.auth.isSignedIn = true
                store.state.auth.isAuthenticating = false
                store.state.auth.authDone = true
            }else{
                if(response.data.error == "no_employee" || response.data.error == "user_not_found") {
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/no_employee").catch(() => {})
                }else{
                    console.error(response.data)
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/error").catch(() => {})
                }
            }
        }
        if(auth.currentUser && auth.currentUser.uid  == profile.sub) {
            const tokenResult = await getIdTokenResult(auth.currentUser, true)
            store.commit("setUserClaims", tokenResult.claims)
            store.commit("setUserProfile", profile)
            const requireReloadToken = await store.dispatch("requireReloadToken")
            if (requireReloadToken) {
                await fetchNewIdToken()
            } else {
                store.state.auth.isSignedIn = true
                store.state.auth.isAuthenticating = false
                store.state.auth.authDone = true
            }
        }else{
            await fetchNewIdToken()
        }
    }else{
        store.commit("setUserClaims", null)
        store.commit("setUserProfile", {})
        await waitForFirebaseAuth()
        if(auth.currentUser != null) {
            await auth.signOut()
        }
        loginNew.onCookieStateChanged(() => {
            loginNew.auth()
        })
        store.state.auth.isSignedIn = false
        store.state.auth.isAuthenticating = true
        store.state.auth.authDone = true
    }
})