<template>
<v-dialog v-model="showDialog" max-width="500">
    <v-card>
        <v-card-title>Hilfe</v-card-title>
        <v-card-text>
            <v-card outlined class="my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        Normal
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined color="blue darken-2" dark class="my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        Abwesenheit
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined color="yellow darken-4" dark class="my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        Uhrzeitänderung
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined color="grey darken-2" dark class="my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        Springer
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined class="my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        <div class="green white--text rounded-pill px-3 py-1 caption d-flex d-sm-none">Schwimmbadänderung</div>
                        <div class="green white--text rounded-pill px-3 py-1 d-none d-sm-flex">Schwimmbadänderung</div>
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined class="plan-entry-item-outlined my-2">
                <v-row class="pa-4">
                    <v-col cols="12" class="vertical-centered-item center-item">
                        Feiertag
                    </v-col>
                </v-row>
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showDialog = false" text color="primary">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data: () => ({
        showDialog: false
    }),
    props: {
        active: {
            type: Number,
            default: 0
        }
    },
    watch: {
        active() {
            this.showDialog = true
        }
    }
}
</script>
<style scoped>
.vertical-centered-item {
    display: flex;
    align-items: center;
}
.right-item {
    justify-content: flex-end;
}
.center-item {
    justify-content: center;
}
.left-item {
    justify-content: left;
}
.plan-entry-item-outlined {
    outline: 5px solid #E53935;
}
</style>