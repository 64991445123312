import { query, collection, onSnapshot } from "firebase/firestore"
import { firestore } from "@/firebase"
import { Pool } from "@/classes/Pool"

const poolsRef = collection(firestore, "pools")

const state = {
    pools: [],
    loading: {
        pools: true
    },
    firebaseListeners: {
        pools: null
    }
}
const getters = {

}
const actions = {
    attachPools({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.pools) {
                state.loading.pools = false
                resolve(state.pools)
                return
            }
            state.loading.pools = true
            state.firebaseListeners.pools = onSnapshot(query(poolsRef), (snapshot) => {
                state.pools = []
                for (const doc of snapshot.docs) {
                    state.pools.push(new Pool(doc))
                }
                state.loading.pools = false
                resolve(state.pools)
            }, (err) => {
                state.loading.pools = false
                reject(err)
            })
        })
    }
}
export default { state, getters, actions }