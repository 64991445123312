<template>
	<v-app-bar v-if="!$store.state.app.isNEWApp" app color="navigation" elevate-on-scroll>
        <a href="/"><img class="v-app-bar-new-icon" :src="$vuetify.theme.dark ? images.logoDark : images.logo" width="90px"></a>
        <v-spacer></v-spacer>
        <v-toolbar-title>Schichtplan</v-toolbar-title>
        <v-spacer></v-spacer>
        <div id="loginnew-dropdown"></div>
        <template v-slot:extension>
            <v-tabs v-model="currentTab" optional grow show-arrows color="primaryLight">
                <v-tab :disabled="(!hasOwnPlan && !$store.state.plan.loading.ownEntries) || !$store.getters.isPoolEmployee">Mein Plan</v-tab>
                <v-tab :disabled="!$store.getters.isPoolEmployee"><span class="d-flex d-sm-none">Allg. Schichtplan</span><span class="d-none d-sm-flex">Allgemeiner Schichtplan</span></v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
    <div v-else>
        <v-app-bar app flat dense color="navigation">
            <v-tabs background-color="primary" optional dark show-arrows v-model="currentTab" grow dense>
                <v-tab :disabled="(!hasOwnPlan && !$store.state.plan.loading.ownEntries) || !$store.getters.isPoolEmployee">Mein Plan</v-tab>
                <v-tab :disabled="!$store.getters.isPoolEmployee"><span class="d-flex d-sm-none">Allg. Schichtplan</span><span class="d-none d-sm-flex">Allgemeiner Schichtplan</span></v-tab>
            </v-tabs>
        </v-app-bar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentTab: 0,
            images: {
                logo: require("@/assets/logo_colored.svg"),
                logoDark: require("@/assets/logo_dark.svg")
            }
        }
    },
    computed: {
        hasOwnPlan() {
            return this.$store.state.plan.ownEntries.length > 0
        }
    },
    watch: {
        "$route.name": {
            handler: function () {
                switch (this.$route.name) {
                case "my_entries":
                    this.currentTab = 0
                    break
                case "plan":
                    this.currentTab = 1
                    break
                default:
                    this.currentTab = null
                    break
                }
            },
            immediate: true
        },
        currentTab(tab) {
            switch(tab) {
            case 0:
                this.$router.push("/my_entries").catch(() => {})
                break
            case 1:
                this.$router.push("/plan").catch(() => {})
                break
            }
        }
    }
}
</script>
<style>
.v-toolbar__content, .v-toolbar__extension {
    position: initial!important 
}
.v-app-bar .v-toolbar__content {
    display: hidden !important
}
</style>