export class PlanEntry {
    #id = null
    employee = {
        personalID: null,
        name: null
    }
    #from = null
    #to = null
    pool = {
        id: null,
        changed: false,
        pools: []
    }
    task = {
        name: null,
        changed: false
    }
    changed = false
    vacation = false
    absent = false
    comment = null
    sp = false

    constructor(doc = null) {
        if (doc != null) {
            this.#id = doc.id
            const data = doc.data()
            this.employee = data.employee
            this.#from = data.from
            this.#to = data.to
            this.pool = data.pool
            this.task = data.task
            this.comment = data.comment
            this.changed = data.changed === true
            this.vacation = data.vacation === true
            this.absent = data.absent === true
            this.sp = data.sp === true
        }
    }

    get id() {
        return this.#id
    }
    get to() {
        if (this.#to) {
            return this.#to.toDate()
        }
        return null
    }
    get from() {
        if (this.#from) {
            return this.#from.toDate()
        }
        return null
    }
}