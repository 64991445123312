export class Pool {
    #id = null
    name = null
    realName = null
    alias = null
    timestamp = null

    constructor(doc = null) {
        if (doc != null) {
            this.#id = doc.id
            const data = doc.data()
            this.name = data.name
            this.realName = data.name
            this.alias = data.alias
            this.timestamp = data.timestamp
            if (this.alias) {
                this.name = this.alias
            }
        }
    }

    get id() {
        return this.#id
    }
}