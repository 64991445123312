import { firestore } from "@/firebase"
import { collection, doc, getDoc } from "firebase/firestore"
import Vue from "vue"

const state = {
    isSignedIn: false,
    authDone: false,
    isAuthenticating: true,
    profile: {},
    userClaims: {
        permissions: {},
        permissionsFor: [],
        personalID: null,
        realPersonalID: null,
        employeeLevel: 0,
        pool: null
    }
}
const getters = {
    isPoolEmployee(state, getters) {
        return getters.isManager || state.userClaims.pool
    },
    isManager(state) {
        return state.userClaims.personalID == state.userClaims.realPersonalID && 
            (state.userClaims.permissions.admin || state.userClaims.permissions.leader || state.userClaims.permissions.manager)
    },
    hasPermissions(state) {
        return state.userClaims.manage || state.userClaims.notify
    }
}
const mutations = {
    setUserClaims(state, claims) {
        if(claims) {
            state.userClaims.permissions = claims.permissions
            state.userClaims.permissionsFor = claims.permissionsFor
            state.userClaims.personalID = claims.personalID
            state.userClaims.realPersonalID = claims.realPersonalID
            state.userClaims.employeeLevel = claims.employeeLevel
            state.userClaims.pool = claims.pool
        }else{
            state.userClaims.permissions = {}
            state.userClaims.permissionsFor = []
            state.userClaims.personalID = null
            state.userClaims.realPersonalID = null
            state.userClaims.employeeLevel = 0
            state.userClaims.pool = null
        }
    },
    setUserProfile(state, profile) {
        Vue.set(state, "profile", profile)
    }
}
const actions = {
    async requireReloadToken({ state }) {
        let reloadToken = false
        if (state.userClaims.realPersonalID != state.userClaims.personalID) {
            reloadToken = true
        }
        const userCollection = collection(firestore, "users")
        const userRef = doc(userCollection, state.userClaims.realPersonalID)
        try {
            const userProfile = await getDoc(userRef)
            if (userProfile.exists) {
                const data = userProfile.data()
                if (!data.sub || data.sub != state.profile.sub) {
                    reloadToken = true
                }
                for (const [key, val] of Object.entries(state.userClaims.permissions)) {
                    if (data.permissions[key] != val) {
                        reloadToken = true
                        break
                    }
                }
                if (data.pool != state.userClaims.pool) {
                    reloadToken = true
                }
            }
        } catch (err) {
            if (sessionStorage.getItem("failingReloadToken") != "true") {
                sessionStorage.setItem("failingReloadToken", "true")
                reloadToken = true
            }
        }
        return reloadToken
    }    
}
export default {state, getters, mutations, actions}