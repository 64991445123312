<template>
<v-dialog v-model="$store.state.app.dialogs.switchView" max-width="500" persistent>
    <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Ansicht wechseln</v-card-title>
        <v-card-text>
            <p>Geben Sie die Personalnummer ein, dessen Ansicht Sie einsehen möchten. Sie werden dann erneut angemeldet.</p>
            <v-text-field v-model="personalID" dense label="Personalnummer" color="primaryLight"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$store.state.app.dialogs.switchView = false">Abbrechen</v-btn>
            <v-btn text @click="switchView" color="primaryLight">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { auth, functions } from "@/firebase"
import { httpsCallable } from "firebase/functions"
import { signOut } from "firebase/auth"

export default {
    data() {
        return {
            isLoading: false,
            personalID: ""
        }
    },
    methods: {
        async switchView() {
            this.isLoading = true
            const switchView = httpsCallable(functions, "switchView")
            try {
                const { data } = await switchView({
                    view: this.personalID
                })
                if (data.response === "ok") {
                    await signOut(auth)
                    location.reload()
                } else {
                    this.$store.commit("showAlert", {
                        title: "Ansicht wechseln",
                        message: "Beim Wechseln der Ansicht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                    })
                    console.error(data)
                    this.isLoading = false
                }
            } catch (err) {
                this.$store.commit("showAlert", {
                    title: "Ansicht wechseln",
                    message: "Beim Wechseln der Ansicht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                })
                console.error(err)
                this.isLoading = false
            }
        }
    }
}
</script>