import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "my_entries" */ "../views/OwnEntriesView.vue")
    },
    {
        path: "/my_entries",
        name: "my_entries",
        component: () => import(/* webpackChunkName: "my_entries" */ "../views/OwnEntriesView.vue")
    },
    {
        path: "/plan",
        name: "plan",
        component: () => import(/* webpackChunkName: "plan" */ "../views/PlanEntriesView.vue")
    },
    {
        path: "/no_employee",
        name: "no_employee",
        component: () => import(/* webpackChunkName: "no_employee" */ "../views/NoEmployeeView.vue")
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/ErrorView.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue")
    },
    {
        path: "/manage/permissions",
        name: "manage_permissions",
        component: () => import(/* webpackChunkName: "manage_permissions" */ "../views/manage/PermissionsView.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
